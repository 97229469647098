type Listener<P> = (payload: P) => Promise<void> | void;

type Listeners<T, K extends keyof T = keyof T> = {
  [P in K]: Listener<T[P]>[];
};

export class Emitter<T> {
  protected listeners = {} as Listeners<T>;

  async emit<K extends keyof T>(name: K, payload: T[K]) {
    const listeners = this.listeners[name] || [];
    for (const listener of listeners) {
      await listener(payload);
    }
  }

  on<K extends keyof T>(name: K, listener: (payload: T[K]) => Promise<void> | void): void {
    (this.listeners[name] = this.listeners[name] || []).push(listener);
  }

  remove<K extends keyof T>(name: K, target: (payload: T[K]) => Promise<void> | void): void {
    const listeners = this.listeners[name];
    if (listeners) {
      listeners.splice(listeners.indexOf(target), 1);
    }
  }
}
