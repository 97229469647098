import type { IWixAPI } from '@wix/yoshi-flow-editor';

type Multilingual = IWixAPI['window']['multilingual'];

export function resolveLocale(wixCodeApi: IWixAPI): string | undefined {
  const { multilingual } = wixCodeApi.window;
  const locale =
    multilingual.isEnabled && multilingual.currentLanguage
      ? findSiteLanguage(multilingual)?.locale
      : wixCodeApi.site.regionalSettings ?? wixCodeApi.site.language;

  return locale || undefined;
}

export function resolveCountryCodeFromMultilingual(wixCodeApi: IWixAPI) {
  const { multilingual } = wixCodeApi.window;
  const { currentLanguage, isEnabled } = multilingual;

  const countryCodeFromMultilingual =
    isEnabled && currentLanguage ? findSiteLanguage(multilingual)?.countryCode : undefined;

  return countryCodeFromMultilingual;
}

function findSiteLanguage(multilingual: Multilingual) {
  return multilingual.siteLanguages.find((language) => language.languageCode === multilingual.currentLanguage);
}
