import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { captureViewerException } from './viewer-errors';

export async function runInteraction<T>(
  flowAPI: ControllerFlowAPI,
  name: string,
  action: () => T | Promise<T>,
  notMonitoredErrorClasses: any[] = [],
) {
  flowAPI.fedops.interactionStarted(name);
  flowAPI.panoramaClient?.transaction(name).start();
  try {
    const result = await action();
    flowAPI.fedops.interactionEnded(name);
    return result;
  } catch (e) {
    if (notMonitoredErrorClasses.find((obj) => e instanceof obj)) {
      flowAPI.fedops.interactionEnded(name);
      flowAPI.panoramaClient?.transaction(name).finish();
    } else {
      captureViewerException(flowAPI, e, {
        tags: { interaction: name },
        contexts: { site: { url: flowAPI.controllerConfig.wixCodeApi.location.url } },
      });
    }
    throw e;
  }
}
