import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';

export class PaymentResultReader {
  constructor(protected result: PaymentCompleteResultPublic) {}

  isOk(): boolean {
    return this.result.clientStatus !== 'Failed';
  }

  getTranslatedError(): string | undefined {
    // @ts-expect-error
    return this.result.translatedError;
  }
}
