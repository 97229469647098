import { TpaPageId } from '@wix/pricing-plans-router-utils';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

type WixCodeApi = ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'];
type AppParams = ControllerParams['flowAPI']['controllerConfig']['appParams'];

export async function isMultiPageApp(wixCodeApi: WixCodeApi, appParams: AppParams): Promise<boolean> {
  if (appParams.appRouters?.length !== 1) {
    return false;
  }
  const sections = await Promise.all(
    [TpaPageId.PackagePicker, TpaPageId.Checkout, TpaPageId.ThankYou, TpaPageId.Paywall].map((sectionId) =>
      wixCodeApi.site.isAppSectionInstalled({ sectionId, appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3' }),
    ),
  );
  return sections.every((section) => section);
}
