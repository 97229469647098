import { ContactField, EmailInfoTag, Form, Kind } from '@wix/ambassador-forms-v4-form/types';

export const simpleFrom: Form = {
  id: '00000000-0000-0000-0000-000000000000',
  fields: [
    {
      id: '769de85d-a2ff-40fb-a267-1d2c11eaece6',
      target: 'last_name_63ea',
      validation: { string: { format: undefined, enum: [] }, required: false },
      pii: true,
      hidden: false,
      view: { label: 'What is your favourite color?', fieldType: 'CONTACTS_LAST_NAME' },
    },
  ],
  steps: [
    {
      id: '0f265a40-ebd4-4282-104c-5d10b9bf5e20',
      name: 'Page 1',
      hidden: false,
      layout: {
        large: {
          items: [{ fieldId: '769de85d-a2ff-40fb-a267-1d2c11eaece6', row: 0, column: 0, width: 12, height: 1 }],
        },
      },
    },
  ],
  rules: [],
  revision: '2',
  createdDate: new Date(),
  updatedDate: new Date(),
  properties: { name: 'My Form 13', disabled: false },
  deletedFields: [],
  kind: Kind.REGULAR,
  postSubmissionTriggers: {
    upsertContact: {
      fieldsMapping: {
        email_1585: { contactField: ContactField.EMAIL, emailInfo: { tag: EmailInfoTag.UNTAGGED } },
        last_name_63ea: { contactField: ContactField.LAST_NAME },
        first_name_2bf1: { contactField: ContactField.LAST_NAME },
      },
      labels: [],
    },
  },
  namespace: 'wix.pricing_plans.v1.custom_form',
  nestedForms: [],
};
