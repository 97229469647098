import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { IWixWindow } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { getPlanCategory, getOrigin } from '../utils/analytics';
import { getPrice } from '../utils/plan';

const { appDefinitionId } = pricingPlans;

export class Analytics {
  constructor(protected window: IWixWindow, protected referralInfo?: string) {}

  setReferralInfo(referralInfo?: string) {
    this.referralInfo = referralInfo;
  }

  addProductImpression(plans: PublicPlan[]) {
    this.window.trackEvent('AddProductImpression', {
      appDefId: appDefinitionId,
      origin: getOrigin(this.referralInfo),
      contents: plans.map((plan) => ({
        id: plan.id,
        name: plan.name,
        category: getPlanCategory(plan),
        price: getPrice(plan),
        currency: plan.pricing?.price?.currency,
        quantity: 1,
      })),
    });
  }

  clickProduct(plan: PublicPlan) {
    this.window.trackEvent('ClickProduct', {
      appDefId: appDefinitionId,
      origin: getOrigin(this.referralInfo),
      name: plan.name ?? undefined,
      category: getPlanCategory(plan),
      price: getPrice(plan),
      currency: plan.pricing?.price?.currency,
    });
  }

  purchase(plan: PublicPlan, order: Order, confirmed: 'yes' | 'no' | 'unknown' = 'unknown') {
    this.window.trackEvent('Purchase', {
      appDefId: appDefinitionId,
      origin: getOrigin(this.referralInfo),
      id: order.id,
      // @ts-expect-error
      orderId: order.id,
      confirmed,
      revenue: getPrice(plan),
      currency: plan.pricing?.price?.currency,
      contents: [
        {
          id: plan.id,
          name: plan.name,
          category: getPlanCategory(plan),
          price: getPrice(plan),
          quantity: 1,
        },
      ],
    });
  }

  viewContent(plan: PublicPlan) {
    this.window.trackEvent('ViewContent', {
      appDefId: appDefinitionId,
      origin: getOrigin(this.referralInfo),
      name: plan.name ?? undefined,
      category: getPlanCategory(plan),
      price: getPrice(plan),
      currency: plan.pricing?.price?.currency,
    });
  }

  selectPayment(payment: string) {
    this.window.trackEvent('CustomEvent', {
      // @ts-expect-error
      event: 'SelectPayment',
      appDefId: appDefinitionId,
      origin: getOrigin(this.referralInfo),
      payment_method: payment,
    });
  }

  initiateCheckout(plan: PublicPlan) {
    this.window.trackEvent('InitiateCheckout', {
      appDefId: appDefinitionId,
      origin: getOrigin(this.referralInfo),
      contents: [
        {
          id: plan.id,
          name: plan.name,
          category: getPlanCategory(plan),
          price: getPrice(plan),
          currency: plan.pricing?.price?.currency,
          quantity: 1,
        },
      ],
    });
  }
}
