import { getPremiumStatus } from '@wix/ambassador-pricing-plans-v1-premium-status/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export class PremiumApi {
  constructor(private httpClient: IHttpClient) {}

  async getPremiumMessageDisplayStatus() {
    const res = await this.getPremiumStatus();
    return !res?.acceptPaymentsEnabled && !res?.hasOldPlans;
  }

  async getPremiumStatus() {
    const response = await this.httpClient.request(getPremiumStatus({}));
    return response.data;
  }

  async shouldUpgrade() {
    const { acceptPaymentsEnabled, acceptPaymentsRequired } = await this.getPremiumStatus();
    return acceptPaymentsRequired && !acceptPaymentsEnabled;
  }
}

export function createPremiumApi(httpClient: IHttpClient) {
  return new PremiumApi(httpClient);
}
