import { Price, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import { Money } from '@wix/ambassador-pricing-plans-v2-plan/types';

export interface FormattedSpannedPrice extends SpannedPrice {
  price: FormattedPrice;
}

interface FormattedPrice extends Price {
  formattedTotal: string;
}

export type MoneyFormatter = (money: Money) => string;

type FormatSpannedPricesProps = {
  spannedPrices: SpannedPrice[] | undefined;
  formatMoney: MoneyFormatter;
};

export const formatSpannedPrices = ({ spannedPrices, formatMoney }: FormatSpannedPricesProps) => {
  return spannedPrices?.map(({ duration, price }) => ({
    duration,
    price: { ...price, formattedTotal: formatMoney({ value: price?.total, currency: price?.currency }) },
  }));
};
