export function isEqual(a: any, b: any) {
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    for (const k in a) {
      if (!isEqual(a[k], b[k])) {
        return false;
      }
    }
    for (const k in b) {
      if (!isEqual(a[k], b[k])) {
        return false;
      }
    }
    return true;
  }
  return a === b;
}
