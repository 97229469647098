import { getSettings } from '@wix/ambassador-pricing-plans-v2-settings/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export function createSettingsApi(httpClient: IHttpClient) {
  return new SettingsApi(httpClient);
}

export class SettingsApi {
  constructor(private httpClient: IHttpClient) {}

  async getSettings() {
    const response = await this.httpClient.request(getSettings({}));
    return response.data.settings;
  }
}
