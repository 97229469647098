import { BenefitBulletType, UserData } from '../types/common';
import { PageType } from './pageType';

export type BIRole = 'member' | 'owner' | 'visitor';

export enum CheckoutError {
  NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD',
  NO_RECURRING_PAYMENT_METHOD = 'NO_RECURRING_PAYMENT_METHOD',
  PLAN_ALREADY_PURCHASED = 'PLAN_ALREADY_PURCHASED',
  NO_PREMIUM = 'NO_PREMIUM',
}

export enum CheckoutAction {
  ApplyCoupon = 'apply_coupon',
  RemoveCoupon = 'remove_coupon',
  CheckTermsAndConditionsCheckbox = 'plan_policy_checkbox_agree',
  UncheckTermsAndConditionsCheckbox = 'plan_policy_checkbox_disagree',
  OpenTermsAndConditionsModal = 'plan_policy_read_click',
}

export const termsAndConditionsCheckboxAction = (newValue: boolean) => {
  return newValue ? CheckoutAction.CheckTermsAndConditionsCheckbox : CheckoutAction.UncheckTermsAndConditionsCheckbox;
};

export function userRoleToBIRole(role: UserData['role']): BIRole {
  switch (role) {
    default:
    case 'Member':
      return 'member';
    case 'Admin':
      return 'owner';
    case 'Visitor':
      return 'visitor';
  }
}

export function toMembershipStatus(pageType: PageType) {
  switch (pageType) {
    case PageType.DEFAULT_SUCCESS:
      return 'success';
    case PageType.VERTICAL_SUCCESS:
      return 'vertical_success';
    case PageType.ERROR:
      return 'error';
    default:
      return undefined;
  }
}

export function toBIPaymentType(recurring?: boolean) {
  return recurring ? 'recurring' : 'one-time';
}

export const SETTINGS_TABS = {
  display: 'display',
  layout: 'layout',
};

export const DISPLAY_VALUES = {
  enabled: 'enabled',
  disabled: 'disabled',
};

export const WIDGET_TYPE = {
  list: 'list',
  page: 'page',
};

export const SUPPORT_LINK = {
  supportArticles: 'Read Support Articles',
  customerCare: 'Contact Customer Care',
};

export const SETTINGS_VIEW_MODE = {
  desktop: 'desktop',
  mobile: 'mobile',
};

export function benefitBulletTypeName(value: BenefitBulletType) {
  return benefitBulletTypeMap[value];
}

const benefitBulletTypeMap = {
  [BenefitBulletType.NONE]: 'none',
  [BenefitBulletType.CIRCLE_TICK]: 'circle_tick',
  [BenefitBulletType.HOLLOW_CIRLCE_TICK]: 'hollow_circle_tick',
  [BenefitBulletType.TICK]: 'tick',
  [BenefitBulletType.ARROW]: 'arrow',
};
