import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';

export const ANALYTICS_ORIGIN = 'Pricing Plans';
export const ANALYTICS_CATEGORY_ONE_TIME = 'One Time';
export const ANALYTICS_CATEGORY_MONTHLY = 'Monthly';
export const ANALYTICS_CATEGORY_WEEKLY = 'Weekly';
export const ANALYTICS_CATEGORY_YEARLY = 'Yearly';

export const getPlanCategory = (plan: PublicPlan) => {
  if (plan.pricing?.subscription) {
    const unit = plan.pricing.subscription.cycleDuration?.unit;
    switch (unit) {
      case PeriodUnit.WEEK:
        return ANALYTICS_CATEGORY_WEEKLY;
      case PeriodUnit.YEAR:
        return ANALYTICS_CATEGORY_YEARLY;
      default:
        return ANALYTICS_CATEGORY_MONTHLY;
    }
  }
  return ANALYTICS_CATEGORY_ONE_TIME;
};

export const getOrigin = (referralInfo?: string) => {
  if (referralInfo) {
    return `${ANALYTICS_ORIGIN} ${referralInfo}`;
  }
  return ANALYTICS_ORIGIN;
};
