export const NO_BREAK_SPACE = '\u00A0';
export const PLANS_QUERY_PAGE_SIZE = 50;

/**
 * 75 is the approximate number of compressed plans GUIDs that can stored in app settings without exceeding character limit.
 */
export const DEFAULT_PLAN_LIMIT = 75;

export const QUERY_REQUEST_LIMIT = 1000;
export const REQUEST_FILTER_LIMIT = 100;

export * from './applications';
