import constant from 'lodash/constant';
import isFunction from 'lodash/isFunction';

const yes = constant(true);

export function getAllPropertyNames<T extends Object, K extends keyof T>(
  o: T,
  filter: (value: any, name: string) => boolean = yes,
): K[] {
  let p = o;
  const names: string[] = [];
  const predicate = (name: string) => {
    // @ts-expect-error
    const value = p[name];
    return filter(value, name);
  };
  do {
    names.splice(0, 0, ...Object.getOwnPropertyNames(p).filter(predicate));
    p = Object.getPrototypeOf(p);
  } while (Object.getPrototypeOf(p));
  return Array.from(new Set(names as K[]));
}

export function wrapMethods<T extends Object, K extends keyof T>(o: T, wrap: (o: T, name: K) => T[K]): T {
  const methods = getAllPropertyNames(o, isFunction);
  for (const method of methods) {
    if (typeof o[method] === 'function') {
      o[method] = wrap(o, method as K);
    }
  }
  return o;
}
