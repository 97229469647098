import { list } from '@wix/ambassador-pricing-plans-v2-benefit/http';
import { Benefit } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID } from '../constants';
import { isTruthy } from '../utils/isTruthy';

export class BenefitsApi {
  constructor(private httpClient: IHttpClient) {}

  public async listPlanBookingsBenefits(planId: string): Promise<Benefit[]> {
    const response = await this.httpClient.request(
      list({
        planIds: [planId],
        appDefIds: [BOOKINGS_APP_DEF_ID],
      }),
    );
    const { benefitsWithPlanInfo = [] } = response.data;
    return benefitsWithPlanInfo
      .filter(({ benefit }) => benefit && benefit.resourceIds?.length)
      .map((info) => info.benefit)
      .filter(isTruthy);
  }
}

export function createBenefitsApi(httpClient: IHttpClient) {
  return new BenefitsApi(httpClient);
}
